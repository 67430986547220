import {
  featuredBlock,
  featuredCollection,
  initImagecarousel,
  reviews,
} from "./slider";

featuredBlock();
featuredCollection();
reviews();
initImagecarousel();

jQuery(function ($) {
  $("#toggle-mobile-menu").on("click", () => {
    $(this).find(".icon").toggleClass("hidden");

    $("#mobile-menu").toggleClass("-translate-x-full");
  });

  $(".toggle-mobile-search").on("click", () => {
    $(".mobile-search, .mobile-overlay").toggleClass("invisible opacity-0");
    $("html").toggleClass("overflow-hidden");
  });

  $(".toggle-wishlist").on("click", () => {
    $(".overlay").toggleClass("invisible opacity-0");
    $("#wishlist-slideout").toggleClass("translate-x-full");
    $("html").toggleClass("overflow-hidden");
  });
});
