import Splide from "@splidejs/splide";

const reviews = () => {
  const element = document.getElementById("reviews");

  if (!element) return;

  new Splide(element, {
    type: "loop",
    gap: 60,
    perPage: 4,
    pagination: false,
    breakpoints: {
      640: {
        perPage: 1,
      },
      768: {
        perPage: 2,
      },
      820: {
        perPage: 3,
      },
    },
  }).mount();
};

const featuredCollection = () => {
  const elements = document.getElementsByClassName("featured-collection");

  for (let i = 0; i < elements.length; i++) {
    new Splide(elements[i], {
      type: "loop",
      perPage: 6,
      gap: 20,
      pagination: false,
      breakpoints: {
        640: {
          perPage: 2,
        },
        1024: {
          perPage: 4,
        },
      },
    }).mount();
  }
};

const featuredBlock = () => {
  const elements = document.getElementsByClassName("featured-block");

  for (let i = 0; i < elements.length; i++) {
    new Splide(elements[i], {
      arrows: false,
      gap: 20,
      pagination: false,
      drag: false,
      breakpoints: {
        640: {
          perPage: 1,
          drag: true,
          padding: { left: 0, right: "10%" },
        },
        820: {
          type: "loop",
          perPage: 2,
          drag: true,
          padding: { left: 0, right: "10%" },
        },
      },
    }).mount();
  }
};

const initImagecarousel = () => {
  const element = document.getElementById("image-carousel");

  if (!element) return;

  new Splide(element, {
    type: "loop",
    gap: 30,
    perPage: 6,
    pagination: false,
    arrows: true,
    breakpoints: {
      640: {
        perPage: 2,
      },
      1024: {
        perPage: 4,
      },
    },
  }).mount();
};

export { featuredBlock, featuredCollection, initImagecarousel, reviews };
